export default {
  apiKey: "AIzaSyDjRGDCRIOLR6S7U3uAjM2PCGDXRgBz7Ww",
  authDomain: "mercarue-185d3.firebaseapp.com",
  databaseURL: "https://mercarue-185d3.firebaseio.com",
  projectId: "mercarue-185d3",
  storageBucket: "mercarue-185d3.appspot.com",
  messagingSenderId: "286663579971",
  appId: "1:286663579971:web:316a63fe08b681942db7f6",
  measurementId: "G-73G18JXCZJ",
};
