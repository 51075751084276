import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase";

Vue.use(Router);

let router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Login
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/admin/authentication/Login"),
    },
    // Registrer

    {
      name: "register",
      path: "/register",
      component: () => import("@/views/admin/authentication/Register"),
      meta: {
        requiresAdmin: true,
      },
    },

    {
      path: "/",
      component: () => import("@/views/admin/Index"),
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/admin/Dashboard"),
          meta: {
            layout: "Tableau de bord",
            requiresEditor: true,
          },
        },
        // Pages

        {
          name: "catalogSettings",
          path: "Mercarue/catalogs/catalogSettings",
          component: () => import("@/views/admin/catalogSettings/Setting"),
          meta: {
            layout: "Catalogue",
            requiresEditor: true,
          },
        },
        {
          name: "PostedProducts",
          path: "Mercarue/Products/PostedProducts",
          component: () => import("@/views/admin/postedProducts/Products"),
          meta: {
            layout: "Produits postés",
            requiresEditor: true,
          },
        },
        {
          name: "gestionClassNames",
          path: "Mercarue/gestionClassNames/ClassNames",
          component: () => import("@/views/admin/gestionClassNames/ClassNames"),
          meta: {
            layout: "Gestion ClassNames",
            requiresEditor: true,
          },
        },
        {
          name: "Orders",
          path: "Mercarue/orders/Orders",
          component: () => import("@/views/admin/orders/Orders"),
          meta: {
            layout: "Gestion des Commandes",
            requiresEditor: true,
          },
        },
        {
          name: "ListeDesFlux",
          path: "Mercanews/ListeDesFlux",
          component: () => import("@/views/admin/mercanews/FluxList"),
          meta: {
            layout: "Liste Des Flux",
            requiresEditor: true,
          },
        },
        {
          name: "AjoutDesActualites",
          path: "Mercanews/AjoutDesActualites",
          component: () => import("@/views/admin/mercanews/AddNews"),
          meta: {
            layout: "Ajouter des actualités",
            requiresEditor: true,
          },
        },
        {
          name: "Parametres",
          path: "MercaAdmin/Parametres",
          component: () => import("@/views/admin/mercanews/Parametres"),
          meta: {
            layout: "Parametres",
            requiresEditor: true,
          },
        },
        {
          name: "CategoriesManagement",
          path: "Mercarue/catalogs/CategoriesManagement",
          component: () =>
            import("@/views/admin/categoriesManagement/Categories"),
          meta: {
            layout: "Gestion des Categories",
            requiresAdmin: true,
          },
        },
        {
          name: "MarkManagement",
          path: "Mercarue/markManagement/MarkManagement",
          component: () => import("@/views/admin/markManagement/Marque"),
          meta: {
            layout: "Gestion des Marques",
            requiresAdmin: true,
          },
        },
        {
          name: "MarkDetail",
          path: "/markManagement/:MarkId?/",
          component: () => import("@/views/admin/markManagement/MarqueDetails"),
          meta: {
            requiresAdmin: true,
          },
        },

        {
          name: "Recruitments",
          path: "MercaAdmin/Recruitments",
          component: () => import("@/views/admin/recruitments/Recruitments"),
          meta: {
            layout: "Candidatures",
            requiresAdmin: true,
          },
        },

        {
          name: "Contacts",
          path: "MercaAdmin/Contacts",
          component: () => import("@/views/admin/contacts/Contacts"),
          meta: {
            layout: "Contact",
            requiresAdmin: true,
          },
        },
        {
          name: "Users",
          path: "MercaAdmin/Users",
          component: () => import("@/views/admin/users/Users"),
          meta: {
            layout: "Gestion des utilisateurs",
            requiresAdmin: true,
          },
        },
        {
          name: "ListeDemande",
          path: "MercaAdmin/ListeDemande",
          component: () =>
            import("@/views/admin/gestionCompteProfessional/ListeDemande"),
          meta: {
            layout: "Validation des comptes PRO de Mercarue",
            requiresAdmin: true,
          },
        },
        {
          name: "AjoutFaq",
          path: "MercaAdmin/AjoutFaq",
          component: () => import("@/views/admin/gestionFaq/AjoutFaq"),
          meta: {
            layout: "Ajout de nouvelles questions/Réponses à la FAQ",
            requiresAdmin: true,
          },
        },
        {
          name: "UserDetail",
          path: "/CustumerDetail/:userId?/",
          component: () => import("@/views/admin/users/UserDetail"),
          meta: {
            requiresAdmin: true,
          },
        },
        {
          name: "AddUser",
          path: "/Users/AddUser",
          component: () => import("@/views/admin/users/AddUser"),
          meta: {
            requiresAdmin: true,
          },
        },
        {
          name: "ProductDetail",
          path: "Products/ProductDetail/:type/:product_id/:mark/",
          component: () => import("@/views/admin/postedProducts/ProductDetail"),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "OrderDetail",
          path: "Order/Order",
          component: () => import("@/views/admin/orders/OrdersDetail"),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "DemandeDetail",
          path: "ListeDemande/DemandeDetail/:demandeId?/",
          component: () =>
            import("@/views/admin/gestionCompteProfessional/DemandeDetail"),
          meta: {
            requiresEditor: true,
          },
        },

        //mobile devices (Smartphone and tablet)
        {
          name: "manage-mobileDevice",
          path: "/manage-mobileDevice/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/mobileDevices/ManageMobileDevice"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-mobileDevice",
          path: "/states-mobileDevice/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/mobileDevices/StatesMobileDevice"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //computer
        {
          name: "manage-ordinateurs",
          path: "/manage-computer/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/computers/ManageComputer"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-ordinateurs",
          path: "/states-ordinateurs/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/computers/StatesComputer"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //console game
        {
          name: "manage-videoGames",
          path: "/manage-videoGames/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/videoGames/ManageVideoGames"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-videoGames",
          path: "/states-videoGames/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/videoGames/StatesVideoGames"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //printer and scanner
        {
          name: "manage-imprimantesScanners",
          path: "/manage-printer/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/printers/ManagePrinter"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-imprimantesScanners",
          path: "/states-printer/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/printers/StatesPrinter"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //screen
        {
          name: "manage-screens",
          path: "/manage-screens/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/screens/ManageScreen"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-screens",
          path: "/states-screens/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/screens/StatesScreen"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //Computer accessory
        {
          name: "manage-computerAccessories",
          path: "/manage-computerAccessories/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/computerAccessories/ManageComputerAccessory"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-computerAccessories",
          path: "/states-computerAccessories/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/computerAccessories/StatesComputerAccessory"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //Computer component
        {
          name: "manage-computerComponents",
          path: "/manage-computerComponents/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/computerComponents/ManageComputerComponent"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-computerComponents",
          path: "/states-computerComponents/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/computerComponents/StatesComputerComponent"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //high-tech
        {
          name: "manage-highTech",
          path: "/manage-highTech/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/highTech/ManageHighTech"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-highTech",
          path: "/states-highTech/:types/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/highTech/StatesHighTech"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //network solution
        {
          name: "manage-networkSolutions",
          path: "/manage-networkSolutions/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/networkSolutions/ManageNetworkSolution"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-networkSolutions",
          path: "/states-networkSolutions/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/networkSolutions/StatesNetworkSolution"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        //storage solution
        {
          name: "manage-storageSolutions",
          path: "/manage-storageSolutions/:purpose/:type/:product_id?/:mark?/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/storageSolutions/ManageStorageSolution"
            ),
          meta: {
            requiresEditor: true,
          },
        },
        {
          name: "states-storageSolutions",
          path: "/states-storageSolutions/:type/",
          component: () =>
            import(
              "@/views/admin/catalogSettings/computer/storageSolutions/StatesStorageSolution"
            ),
          meta: {
            requiresEditor: true,
          },
        },

        {
          name: "User Profile",
          path: "pages/user",
          component: () =>
            import("@/views/materialDashboard/pages/UserProfile"),
        },
        {
          name: "Notifications",
          path: "components/notifications",
          component: () =>
            import(
              "@/views/materialDashboard/components/core/component/Notifications"
            ),
        },
        {
          name: "Icons",
          path: "components/icons",
          component: () =>
            import("@/views/materialDashboard/components/core/component/Icons"),
        },
        /*
        {
          name: "Typography",
          path: "components/typography",
          component: () => import("@/views/admin/component/Typography"),
        },
        // Tables
        {
          name: "Regular Tables",
          path: "tables/regular-tables",
          component: () => import("@/views/admin/tables/RegularTables"),
        },
        // Maps
        {
          name: "Google Maps",
          path: "maps/google-maps",
          component: () => import("@/views/admin/maps/GoogleMaps"),
        },
        // Upgrade
        {
          name: "Upgrade",
          path: "upgrade",
          component: () => import("@/views/admin/Upgrade"),
        },*/
      ],
    },
  ],
});

// Nav Guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (firebase.auth().currentUser != null) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((tokenResult) => {
          if (!tokenResult.claims.admin) {
            alert("vous n'êtes pas autorisé à être ici");
          } else {
            // Proceed to route
            next();
          }
        });
    } else {
      // Go to login
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else if (to.matched.some((record) => record.meta.requiresEditor)) {
    if (firebase.auth().currentUser != null) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((tokenResult) => {
          // Check if NO logged user
          if (!tokenResult.claims.editor) {
            alert("vous n'êtes pas autorisé à être ici");
          } else {
            // Proceed to route
            next();
          }
        });
    } else {
      // Go to login
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    // Proceed to route
    next();
  }
});

export default router;
